import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;
	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"   viewBox="0 0 500.000000 500.000000">
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)">


<path d="M4824 6830 c-232 -60 -398 -240 -439 -474 -23 -134 -22 -1179 1
-1292 50 -243 212 -411 451 -469 60 -14 137 -16 643 -13 531 3 579 4 625 22
100 37 161 72 222 130 81 75 137 162 168 259 l25 78 0 641 c0 622 -1 643 -21
711 -32 107 -66 164 -149 248 -55 56 -95 85 -150 112 -133 65 -156 67 -763 66
-493 -1 -548 -2 -613 -19z m1208 -71 c75 -16 185 -70 243 -121 63 -56 121
-145 146 -228 19 -63 20 -90 17 -720 l-3 -655 -43 -87 c-71 -142 -189 -239
-334 -273 -96 -23 -1133 -22 -1221 1 -191 49 -332 198 -373 394 -13 63 -15
162 -12 680 4 596 4 606 26 664 69 184 210 311 385 346 66 12 1106 12 1169 -1z"/>
<path d="M4935 6539 c-149 -22 -280 -133 -338 -288 -20 -53 -21 -78 -25 -461
-2 -250 1 -435 7 -484 21 -154 109 -275 251 -344 l75 -37 525 0 525 0 75 36
c116 56 187 132 231 249 l24 65 0 460 0 460 -24 65 c-55 150 -182 255 -338
279 -85 13 -897 13 -988 0z m231 -344 c104 -31 180 -130 191 -251 l6 -64 -97
0 -96 0 0 29 c0 39 -17 81 -38 97 -12 9 -54 13 -134 14 l-116 0 -26 -31 c-28
-33 -31 -53 -17 -92 16 -42 66 -57 193 -57 133 0 172 -9 230 -53 64 -49 99
-113 105 -190 11 -133 -40 -228 -152 -283 -52 -25 -63 -26 -202 -27 -128 0
-154 3 -193 20 -106 48 -170 149 -170 267 l0 46 95 0 95 0 0 -41 c0 -25 8 -52
21 -70 l20 -29 129 0 c127 0 129 0 149 26 29 37 27 89 -4 119 -23 24 -30 25
-138 25 -130 0 -201 17 -262 61 -48 36 -91 98 -106 153 -35 130 53 291 181
331 63 19 272 19 336 0z m444 -213 c3 -89 8 -162 13 -162 4 0 75 0 157 0 l150
0 0 165 0 165 95 0 95 0 0 -410 0 -410 -95 0 -95 0 0 170 0 170 -160 0 -160 0
0 -170 0 -170 -100 0 -100 0 0 403 c0 222 3 407 7 411 4 4 48 5 98 4 l90 -3 5
-163z"/>
<path d="M3882 4449 c-60 -12 -98 -42 -118 -94 -34 -90 19 -166 137 -199 57
-15 109 -52 109 -76 0 -18 -39 -50 -61 -50 -12 0 -34 14 -49 30 -25 26 -35 30
-83 30 -31 0 -59 -4 -62 -10 -12 -20 35 -89 78 -115 35 -20 57 -25 111 -25
116 0 186 55 186 148 0 82 -29 109 -174 164 -66 26 -84 52 -60 87 20 28 60 27
88 -4 20 -21 32 -25 85 -25 60 0 62 1 56 23 -14 45 -41 81 -75 98 -44 22 -113
29 -168 18z"/>
<path d="M5912 4449 c-122 -24 -202 -124 -202 -252 0 -72 18 -122 62 -171 52
-59 111 -86 192 -86 97 0 162 33 221 113 25 33 29 50 33 121 4 69 1 90 -19
135 -45 106 -165 165 -287 140z m112 -114 c69 -33 99 -152 56 -222 -49 -81
-173 -85 -226 -6 -13 20 -18 47 -19 90 0 71 20 107 76 135 42 22 71 23 113 3z"/>
<path d="M6413 4450 c-23 -5 -51 -14 -62 -19 -66 -36 -89 -155 -41 -207 29
-30 81 -59 125 -68 57 -12 95 -43 95 -76 0 -55 -73 -65 -106 -14 -14 21 -24
24 -80 24 l-64 0 6 -27 c19 -80 90 -125 194 -124 56 0 76 5 109 25 48 31 71
71 71 125 0 78 -33 111 -155 155 -90 33 -115 64 -79 100 27 27 94 11 94 -23 0
-7 24 -11 69 -11 l70 0 -10 32 c-5 18 -9 34 -9 35 0 7 -58 56 -75 62 -35 14
-109 19 -152 11z"/>
<path d="M4210 4271 c0 -192 6 -222 54 -274 37 -39 85 -57 159 -57 84 0 142
29 179 90 27 44 27 48 29 230 l2 185 -59 0 -59 0 -5 -177 c-6 -207 -8 -213
-91 -213 -79 0 -87 21 -88 238 l-1 157 -60 0 -60 0 0 -179z"/>
<path d="M4727 4443 c-4 -3 -7 -118 -7 -255 l0 -248 143 0 c206 1 257 28 257
139 0 50 -3 57 -37 89 l-38 36 33 32 c28 28 32 39 32 82 0 62 -32 106 -88 122
-41 11 -285 14 -295 3z m247 -109 c20 -20 21 -58 1 -74 -8 -7 -41 -13 -73 -14
l-57 -1 -3 39 c-2 21 -1 44 2 52 8 20 110 19 130 -2z m14 -202 c18 -23 15 -56
-7 -76 -14 -13 -33 -16 -77 -14 l-59 3 -3 44 c-2 24 -1 49 1 55 7 19 129 8
145 -12z"/>
<path d="M5200 4195 l0 -255 60 0 60 0 0 105 0 105 95 0 94 0 3 -102 3 -103
60 0 60 0 0 250 0 250 -60 0 -60 0 -1 -95 -2 -95 -96 -3 -96 -3 0 101 0 100
-60 0 -60 0 0 -255z"/>
<path d="M6717 4443 c-9 -8 -9 -56 -1 -77 5 -12 21 -16 70 -16 l64 0 0 -205 0
-206 58 3 57 3 3 203 2 202 65 0 65 0 0 50 0 50 -188 0 c-104 0 -192 -3 -195
-7z"/>
</g>
</svg>


		</Box >
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
